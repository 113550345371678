<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <h2 id="title-of-block-check">Logged Out</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p>You have been successfully logged out!</p>
            <p>
              Please click
              <a @click="goBack" href="#">here</a> to go back to the last application.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'loggedOut',
  methods: {
    goBack () {
      history.back()
    }
  }
}
</script>
<style>
</style>

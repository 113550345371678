var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("p", [_vm._v("You have been successfully logged out!")]),
            _c("p", [
              _vm._v(" Please click "),
              _c("a", { attrs: { href: "#" }, on: { click: _vm.goBack } }, [
                _vm._v("here")
              ]),
              _vm._v(" to go back to the last application. ")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", { attrs: { id: "title-of-block-check" } }, [
          _vm._v("Logged Out")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }